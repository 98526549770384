<template>
  <div class="s-default-layout">
    <img
      v-if="pixelSrc"
      :src="pixelSrc"
      style="display: none"
      width="1"
      height="1"
      alt=""
    />
    <ModalAge
      :show="shouldShowAgeModal"
      @yes="SET_IS_ADULT(true)"
      @no="userIsChild"
    />
    <ModalXLoginPopup />
    <ModalChange />
    <slot />
  </div>
  <MobileMenu />
</template>
<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'

  export default {
    data() {
      return {
        showAgeModal: false,
        pixelSrc: null
      }
    },
    computed: {
      ...mapGetters('auth', ['xShouldShowAgePopup']),

      shouldShowAgeModal() {
        return this.xShouldShowAgePopup && this.showAgeModal
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.showAgeModal = true
        this.xCloseMobileNavbar()
        this.generatePixelSrc()
      })
    },
    methods: {
      ...mapActions(['xCloseMobileNavbar']),
      ...mapMutations('auth', ['SET_IS_ADULT']),

      userIsChild() {
        window.location = 'https://www.google.com'
      },
      currentUrl() {
        return typeof window !== 'undefined' ? window.location.href : ''
      },
      generatePixelSrc() {
        this.pixelSrc = `https://ad.ipredictive.com/d/track/event?upid=113285&url=${encodeURIComponent(
          this.currentUrl()
        )}&cache_buster=${Date.now()}&ps=2&p1=pageview`
      }
    },
    watch: {
      $route() {
        this.generatePixelSrc()
      }
    }
  }
</script>
